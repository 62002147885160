import { defineComponent } from 'vue';
export default defineComponent({
  name: 'GoToStripeCell',
  data: function data() {
    return {
      params: {},
      stripeCustomerId: ''
    };
  },
  computed: {
    stripeCustomerLink: function stripeCustomerLink() {
      if (this.stripeCustomerId) {
        return "https://dashboard.stripe.com/customers/".concat(this.stripeCustomerId);
      }
      return '#';
    }
  },
  beforeMount: function beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.setValues();
  },
  methods: {
    setValues: function setValues() {
      this.stripeCustomerId = this.params.value;
    }
  }
});