"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDashboardsApi = void 0;
function createDashboardsApi(api) {
    return {
        getExecutiveDashbard: (von, bis, leistungserbringer) => api.metrics.get(`/api/dashboard/executive`, {
            query: { von, bis, leistungserbringer },
        }),
        getDashboardSettings: () => api.metrics.get(`/api/dashboard/settings`),
        saveDashboardSettings: (params) => api.metrics.post(`/api/dashboard/settings`, params),
    };
}
exports.createDashboardsApi = createDashboardsApi;
