var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "wrapper fill-height"
  }, [_vm._t("main"), _vm.showDetail ? _c('div', {
    staticClass: "paddingSpacer"
  }) : _vm._e(), _vm.showDetail ? _vm._t("detail", function () {
    return [_vm._m(0)];
  }) : _vm._e()], 2);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "emptyNotice"
  }, [_c('p', [_vm._v("Bitte Datensatz auswählen")])]);
}];
export { render, staticRenderFns };