import { isNil, isNumber } from 'lodash';
import numbro from 'numbro';

export const bytesFilter = {
  filters: {
    bytes(value: any, fallback = '-') {
      if (isNil(value)) {
        return fallback;
      }
      if (isNumber(value)) {
        value += 0.00001;
      }
      return numbro(value).format({
        output: 'byte',
        base: 'binary',
      });
    },
  },
};
