"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createAbrechnungsvorschlaegeApi = void 0;
function createAbrechnungsvorschlaegeApi(api) {
    return {
        vorschlagAblehnen: (data) => api.metrics.post(`/api/vorschlagablehnen`, data),
        vorschlagAblehnenId: (id) => api.metrics.delete(`/api/vorschlagablehnen/${id}`),
        getAbrechnungsvorschlaege: (data) => api.metrics.get(`/api/abrechnungsvorschlaege`, { query: data }),
        getIgnorierteVorschlaege: (patid, regel) => api.metrics.get(`/api/abgelehntevorschlaege/${patid}/${regel}`),
        getLatestBemaQuartal: () => api.metrics.get(`/api/abrechnungsvorschlaege/latestbemaquartal`),
        getAbrechnungsKPIs: (params) => api.metrics.get(`/api/abrechnung/kpis`, {
            query: params,
        }),
    };
}
exports.createAbrechnungsvorschlaegeApi = createAbrechnungsvorschlaegeApi;
