import ClientsClientIdCell from '@/components/cells/ClientsClientIdCell.vue';
import BooleanIconCell from '@/components/cells/BooleanIconCell.vue';
import CopyTextCell from '@/components/cells/CopyTextCell.vue';
import TagsCell from '@/components/cells/TagsCell.vue';
import BotVersionCell from '@/components/cells/BotVersionCell.vue';
import ClientImportCell from '@/components/cells/ClientImportCell.vue';
import VertraegeCell from '@/components/cells/VertraegeCell.vue';
import PvsCell from '@/components/cells/PvsCell.vue';
import FeaturesCell from '@/components/cells/FeaturesCell.vue';
import ClientIdCell from '@/components/cells/ClientIdCell.vue';
import LinkCellComponent from '@/components/cells/LinkCellComponent.vue';
import RewriteInvoiceCellComponent from '@/components/cells/RewriteInvoiceCellComponent.vue';
import ReMailInvoiceCellComponent from '@/components/cells/ReMailInvoiceCellComponent.vue';
import ActionButtonCell from '@/components/cells/ActionButtonCell.vue';
import GoToUsersCell from '@/components/cells/GoToUsersCell.vue';
import OnboardingProcessStatusCell from '@/components/cells/OnboardingProcessStatusCell.vue';
import AboStatusCell from '@/components/cells/AboStatusCell.vue';
import OnboardingMainStatusCell from '@/components/cells/OnboardingMainStatusCell.vue';
import TextEditCell from '@rose/common-ui/src/components/cells/TextEditCell.vue';
import TicketStatusCell from '@/components/cells/TicketStatusCell.vue';
import TicketPrioCell from '@/components/cells/TicketPrioCell.vue';
import ContactPositionCell from '@/components/cells/ContactPositionCell.vue';
import KostenfreiCell from '@/components/cells/KostenfreiCell.vue';
import ImportTypeCell from '@/components/cells/ImportTypeCell.vue';
import MetricsLicenceCountCell from '@/components/cells/MetricsLicenceCountCell.vue';
import GoToStripeCell from '@/components/cells/GoToStripeCell.vue';

export const columnCellRendererComponents = {
  ClientsClientIdCell,
  BooleanIconCell,
  ImportTypeCell,
  CopyTextCell,
  TagsCell,
  BotVersionCell,
  ClientImportCell,
  VertraegeCell,
  PvsCell,
  FeaturesCell,
  ClientIdCell,
  LinkCellComponent,
  RewriteInvoiceCellComponent,
  ReMailInvoiceCellComponent,
  ActionButtonCell,
  GoToUsersCell,
  OnboardingProcessStatusCell,
  OnboardingMainStatusCell,
  AboStatusCell,
  TextEditCell,
  TicketStatusCell,
  TicketPrioCell,
  ContactPositionCell,
  KostenfreiCell,
  MetricsLicenceCountCell,
  GoToStripeCell,
};
