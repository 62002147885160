var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "grid-wrap"
  }, [_c('ag-grid-vue', {
    staticClass: "data-grid ag-theme-balham mt-2",
    attrs: {
      "row-data": _vm.filteredLicenceData,
      "column-defs": _vm.columnDefs,
      "grid-options": _vm.gridOptions,
      "get-row-id": _vm.getRowId,
      "pinnedBottomRowData": _vm.summaryRow
    },
    on: {
      "cellClicked": _vm.onCellClicked,
      "selectionChanged": _vm.onSelectionChange,
      "grid-ready": _vm.onGridReady,
      "model-updated": _vm.onRowCountChanged
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };