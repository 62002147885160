import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.join.js";
import { defineComponent } from 'vue';
import { euroFilter } from '@rose/common-ui';
export default defineComponent({
  name: 'MetricsLicenceCountCell',
  mixins: [euroFilter],
  data: function data() {
    return {
      params: null,
      clientIds: [],
      row: undefined,
      metricsField: '',
      stripeField: '',
      title: ''
    };
  },
  computed: {
    licenceInfo: function licenceInfo() {
      var _a;
      if (this.ab) {
        return "".concat(((_a = this.ab) === null || _a === void 0 ? void 0 : _a.billableRounded) || '-', " / ").concat(this.stripe || '-');
      }
      return '-';
    },
    ab: function ab() {
      var _a, _b, _c;
      return (_c = (_b = (_a = this.row) === null || _a === void 0 ? void 0 : _a.meta) === null || _b === void 0 ? void 0 : _b.aktiveBehandler) === null || _c === void 0 ? void 0 : _c[this.metricsField];
    },
    stripe: function stripe() {
      var _a, _b, _c;
      return (_c = (_b = (_a = this.row) === null || _a === void 0 ? void 0 : _a.meta) === null || _b === void 0 ? void 0 : _b.stripe) === null || _c === void 0 ? void 0 : _c[this.stripeField];
    },
    licenceMissmatch: function licenceMissmatch() {
      var _a;
      return this.ab && this.stripe !== ((_a = this.ab) === null || _a === void 0 ? void 0 : _a.billableRounded);
    }
  },
  beforeMount: function beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.setValues();
  },
  methods: {
    // gets called whenever the user gets the cell to refresh
    refresh: function refresh(params) {
      // set value into cell again
      //   this.cellValue = this.getValueToDisplay(params);
      this.setValues();
      return true;
    },
    setValues: function setValues() {
      var _a, _b, _c, _d;
      this.metricsField = (_a = this.params) === null || _a === void 0 ? void 0 : _a.metrics;
      this.stripeField = (_b = this.params) === null || _b === void 0 ? void 0 : _b.stripe;
      this.title = (_c = this.params) === null || _c === void 0 ? void 0 : _c.title;
      if ((_d = this.params) === null || _d === void 0 ? void 0 : _d.data) {
        this.row = this.params.data;
      }
    },
    joinLeistungen: function joinLeistungen(leistungen) {
      return (leistungen === null || leistungen === void 0 ? void 0 : leistungen.join(', ')) || '-';
    }
  }
});