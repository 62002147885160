"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createFeedbackApi = void 0;
function createFeedbackApi(api) {
    return {
        saveFeedback: (params) => api.r4cDispatch.put(`/factoring/feedback`, params),
        r4cLog: (params) => api.r4cInstance.post(`/factoring/r4clog`, params),
    };
}
exports.createFeedbackApi = createFeedbackApi;
