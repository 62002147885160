"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatApiErrorForUser = exports.extractRequestIdFromError = exports.truncateString = void 0;
const axios_1 = require("axios");
const types_1 = require("../../../types");
function truncateString(str, maxLength) {
    if (str === null || str === undefined) {
        return '';
    }
    if (typeof str !== 'string') {
        str = String(str);
    }
    if (str.length > maxLength) {
        return str.substring(0, maxLength) + '...';
    }
    return str;
}
exports.truncateString = truncateString;
function extractRequestIdFromError(err) {
    if ((0, axios_1.isAxiosError)(err)) {
        return err.response?.headers[types_1.HEADERS.ROSEREQUESTID];
    }
}
exports.extractRequestIdFromError = extractRequestIdFromError;
function formatApiErrorForUser(err, truncateLength = 100) {
    if ((0, axios_1.isAxiosError)(err)) {
        const parts = [];
        if (err.response?.headers[types_1.HEADERS.ROSEREQUESTID]) {
            parts.push(`rid: ${err.response?.headers[types_1.HEADERS.ROSEREQUESTID]}`);
        }
        let errMsg = err.message;
        // convert "Request failed with status code 500" to short version "Code 500"
        errMsg = errMsg.replace(/Request failed with status code (\d+)/, 'Code $1');
        parts.push(errMsg);
        if (err.response?.data?.error) {
            parts.push(truncateString(err.response?.data?.message || err.response?.data?.error, truncateLength));
        }
        if (!err.response) {
            parts.push('Keine Antwort vom Server erhalten');
        }
        return parts.join(' | ');
    }
    return truncateString(err.data?.message || err.message || String(err), truncateLength);
}
exports.formatApiErrorForUser = formatApiErrorForUser;
