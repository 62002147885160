"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createTermineApi = void 0;
function createTermineApi(api) {
    return {
        getTerminqualitaet: (von, bis, privat, behandler, leistungserbringer) => api.metrics.get(`/api/terminqualitaet`, {
            query: { von, bis, behandler, leistungserbringer, privat },
        }),
        getTerminqualitaetKpis: (params) => api.metrics.get(`/api/terminqualitaet/kpis?`, { query: params }),
    };
}
exports.createTermineApi = createTermineApi;
