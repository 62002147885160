"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createMainApi = void 0;
function createMainApi(api) {
    return {
        collectionStats: (params) => api.metrics.get(`/api/collectionstats`, {
            query: {
                ...params,
                from: params.from?.toISOString(),
                to: params.to?.toISOString(),
            },
        }),
        importProtocol: (id) => api.metrics.get(`/api/importprotocol/${id}`),
        getApiKey: () => api.metrics.get(`/api/apikey`),
        getViews: (all) => api.metrics.get(`/api/views`, { query: { all } }),
    };
}
exports.createMainApi = createMainApi;
