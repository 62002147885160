"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createSupportApi = void 0;
function createSupportApi(api) {
    return {
        checkToken: (token) => api.metrics.get(`/auth/checksupportformtoken`, { query: { token } }),
        searchCustomers: (query) => api.metrics.get(`/support/customer/search`, { query: { query } }),
        getCustomerContacts: (helpdeskid) => api.metrics.get(`/support/contact/${helpdeskid}/search`),
        createTicket: (ticket) => api.metrics.post(`/support/ticket`, ticket),
    };
}
exports.createSupportApi = createSupportApi;
