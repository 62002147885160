import { DirectiveBinding, isVue2, isVue3 } from 'vue-demi';
import { MetricsApi } from '../../../base';

async function updateElement(el: HTMLElement, binding: DirectiveBinding<any>, metricsApi: MetricsApi) {
  if (binding.value === binding.oldValue) {
    return;
  }
  const imageId = binding.value;

  if (!imageId) {
    return;
  }

  // support for inlined data urls
  if (imageId.startsWith('data:')) {
    el.setAttribute('src', imageId);
    return;
  }

  const dataUri = await metricsApi.imageUpload.getImageDataUri(imageId);
  el.setAttribute('src', dataUri);
}

export function installRoseImageDirective(vue: any, metricsApi: MetricsApi) {
  if (isVue2) {
    vue.directive('rose-image', {
      bind(el: any, binding: any, vnode: any) {
        void updateElement(el, binding, metricsApi);
      },
      update(el: any, binding: any, vnode: any) {
        void updateElement(el, binding, metricsApi);
      },
    });
  }

  if (isVue3) {
    vue.directive('rose-image', {
      beforeMount(el: any, binding: any, vnode: any, prevVnode: any) {
        void updateElement(el, binding, metricsApi);
      },
      updated(el: any, binding: any, vnode: any, prevVnode: any) {
        void updateElement(el, binding, metricsApi);
      },
    });
  }
}
