"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createSettingsApi = void 0;
const types_1 = require("../../../../types");
function createSettingsApi(api) {
    return {
        getSettings: (noCache = false) => api.metrics.get(`/api/settings`, {
            headers: { [types_1.HEADERS.ROSECACHECONTROL]: noCache ? types_1.HEADERVALUES.NOCACHE : '' },
        }),
        updateSettings: (params) => api.metrics.put(`/api/settings`, { ...params }),
        getBenchmarkGroups: () => api.metrics.get(`/api/benchmarkgroups`),
        patchSettings: (settingsDiff) => api.metrics.put(`/api/settings/patch`, { settingsDiff }),
    };
}
exports.createSettingsApi = createSettingsApi;
