import { VChip } from 'vuetify/lib/components/VChip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "cid-column"
  }, [_vm.isCharlyAnalyticsCustomer(_vm.row) ? _c(VChip, {
    staticClass: "pl-2 pr-2",
    attrs: {
      "x-small": "",
      "label": "",
      "pill": "",
      "color": "#ffdc00"
    }
  }, [_c('div', {
    staticClass: "ca"
  }, [_vm._v("ca")])]) : _vm._e(), _c('CopyText', {
    attrs: {
      "value": _vm.info,
      "className": {
        veraltet: _vm.row && _vm.row.veraltet,
        billingonly: _vm.row && _vm.row.billingonly,
        freerider: _vm.row && _vm.row.freeriderlizenzen && _vm.row.freeriderlizenzen.length <= 0
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };