import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "d-flex justify-center",
    attrs: {
      "title": "Go to stripe"
    }
  }, [_c(VBtn, {
    attrs: {
      "href": _vm.stripeCustomerLink,
      "x-small": "",
      "icon": "",
      "target": "_blank"
    }
  }, [_c(VIcon, [_vm._v("fa-cc-stripe")])], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };