import { VBtn } from 'vuetify/lib/components/VBtn';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "charlyUplaods"
  }, [_c('h3', [_vm._v("Retry failed upload task")]), _c('div', {
    staticClass: "d-flex align-baseline mt-2 mb-4"
  }, [_c(VTextField, {
    staticStyle: {
      "max-width": "160px"
    },
    attrs: {
      "label": "cid",
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.manualRetryUploadCid,
      callback: function callback($$v) {
        _vm.manualRetryUploadCid = $$v;
      },
      expression: "manualRetryUploadCid"
    }
  }), _c('div', {
    staticClass: "mx-1"
  }), _c(VTextField, {
    staticStyle: {
      "max-width": "200px"
    },
    attrs: {
      "label": "upload task id",
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.manualRetryUploadId,
      callback: function callback($$v) {
        _vm.manualRetryUploadId = $$v;
      },
      expression: "manualRetryUploadId"
    }
  }), _c(VBtn, {
    staticClass: "ml-2",
    attrs: {
      "color": "primary",
      "outlined": "",
      "dense": "",
      "disabled": !_vm.manualRetryUploadCid || !_vm.manualRetryUploadId
    },
    on: {
      "click": _vm.retryManuelUpload
    }
  }, [_vm._v("Retry")])], 1), _c('h3', [_vm._v("Failed charly Uploads (" + _vm._s(_vm.failedUploads.length) + ")")]), _c('div', {
    staticClass: "tableWrap"
  }, [_vm.failedUploads.length > 0 ? _c('table', {
    staticClass: "resultTable"
  }, [_c('tr', [_c('td'), _vm._l(_vm.getColumns(_vm.failedUploads), function (column) {
    return _c('th', {
      key: column
    }, [_vm._v(_vm._s(column))]);
  })], 2), _vm._l(_vm.failedUploads, function (fl) {
    return _c('tr', {
      key: fl.id
    }, [_c('td', [_c(VBtn, {
      attrs: {
        "small": "",
        "color": "primary",
        "outlined": ""
      },
      on: {
        "click": function click($event) {
          return _vm.log(fl);
        }
      }
    }, [_vm._v("log")]), _c(VBtn, {
      attrs: {
        "small": "",
        "color": "primary",
        "outlined": ""
      },
      on: {
        "click": function click($event) {
          return _vm.retry(fl);
        }
      }
    }, [_vm._v("retry")])], 1), _vm._l(_vm.getColumns(_vm.failedUploads), function (column) {
      return _c('td', {
        key: column
      }, [_vm._v(_vm._s(_vm.toStringWithTrucate(fl[column])))]);
    })], 2);
  })], 2) : _vm._e()])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };