import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('MasterDetailComponent', {
    attrs: {
      "showDetail": _vm.view !== _vm.View.METRICSLICENCES
    },
    scopedSlots: _vm._u([{
      key: "main",
      fn: function fn() {
        return [_c('div', {
          staticClass: "subscriptions pt-1 pb-0 px-2"
        }, [_c('div', {
          staticClass: "d-flex flex-column"
        }, [_c('div', {
          staticClass: "d-flex justify-space-between mb-2 align-center"
        }, [_c(VBtnToggle, {
          attrs: {
            "mandatory": "",
            "color": "primary",
            "value": _vm.view
          },
          on: {
            "change": _vm.onViewChange
          }
        }, [_c(VBtn, {
          attrs: {
            "value": _vm.View.OVERVIEW,
            "title": "Liste",
            "small": ""
          }
        }, [_c('span', [_vm._v("Overview")])]), _c(VBtn, {
          attrs: {
            "value": _vm.View.SUBSCRIPTIONS,
            "title": "Kacheln",
            "small": ""
          }
        }, [_c('span', [_vm._v("Subscriptions")])]), _c(VBtn, {
          attrs: {
            "value": _vm.View.INVOICES,
            "title": "Kompakt",
            "small": ""
          }
        }, [_c('span', [_vm._v("Invoices")])]), _c(VBtn, {
          attrs: {
            "value": _vm.View.METRICSLICENCES,
            "title": "Kompakt",
            "small": ""
          }
        }, [_c('span', [_vm._v("Metrics-Lizenz-Check")])])], 1), _vm.view !== _vm.View.METRICSLICENCES ? _c('div', {
          staticClass: "date d-flex align-center"
        }, [_c(VBtnToggle, {
          attrs: {
            "color": "primary"
          },
          on: {
            "change": _vm.changeDateFilter
          }
        }, [_c(VBtn, {
          attrs: {
            "small": "",
            "value": _vm.dateFilterValues.START,
            "disabled": _vm.view === _vm.View.INVOICES
          }
        }, [_vm._v("Abo-Start")]), _c(VBtn, {
          attrs: {
            "small": "",
            "value": _vm.dateFilterValues.END,
            "disabled": _vm.view === _vm.View.INVOICES
          }
        }, [_vm._v("Gekündigt")]), _c(VBtn, {
          attrs: {
            "small": "",
            "value": _vm.dateFilterValues.RUNNING,
            "disabled": _vm.view === _vm.View.INVOICES
          }
        }, [_vm._v("Abonniert")]), _c(VBtn, {
          staticStyle: {
            "display": "none"
          }
        })], 1), _c('DatePicker', {
          attrs: {
            "dateRange": _vm.gridFilters.dateRange,
            "disabled": _vm.view === _vm.View.INVOICES
          },
          on: {
            "dateUpdate": _vm.dateUpdate
          }
        })], 1) : _vm._e(), _c(VBtn, {
          attrs: {
            "small": "",
            "title": "Export",
            "color": "secondary",
            "outlined": "",
            "disabled": _vm.view !== _vm.View.METRICSLICENCES
          },
          on: {
            "click": _vm.exportGrid
          }
        }, [_c(VIcon, {
          attrs: {
            "small": "",
            "left": ""
          }
        }, [_vm._v("fas fa-file-export")]), _c('span', [_vm._v("Export")])], 1)], 1)]), _vm.view === _vm.View.METRICSLICENCES ? _c('div', {
          staticClass: "d-flex flex-eins"
        }, [_c('MetricsLicenceCheckGrid', {
          ref: "metricsLicenceCheck",
          attrs: {
            "is-loading": _vm.isLoading,
            "licenceData": _vm.licenceData
          },
          on: {
            "row-count-changed": _vm.onRowCountChange
          }
        })], 1) : _c('div', {
          staticClass: "d-flex pt-1 align-top"
        }, [_c('div', {
          staticClass: "d-flex flex-wrap align-center"
        }, [_c(VIcon, {
          staticClass: "mr-1 pb-1",
          attrs: {
            "small": ""
          }
        }, [_vm._v("far fa-filters")]), _c('span', {
          staticClass: "filter-label pr-2 pb-1"
        }, [_vm._v("feature")]), _vm._l(_vm.features, function (feature) {
          return _c('div', {
            key: feature,
            staticClass: "d-flex pr-1 pb-1"
          }, [_c(VChip, {
            attrs: {
              "value": feature,
              "x-small": "",
              "label": "",
              "color": _vm.featureSelected === feature ? "#9280b8" : "#544179",
              "dark": ""
            },
            on: {
              "click": function click($event) {
                return _vm.onFeatureClick(feature);
              }
            }
          }, [_c('span', [_vm._v(_vm._s(feature))])])], 1);
        }), _c('span', {
          staticClass: "filter-label pr-2 pb-1"
        }, [_vm._v("status")]), _c('div', {
          staticClass: "d-flex pr-1 pb-1"
        }, [_c(VChip, {
          attrs: {
            "value": "good",
            "x-small": "",
            "label": "",
            "color": _vm.statusSelected === "good" ? "green lighten-3" : "green",
            "dark": "",
            "disabled": _vm.view !== _vm.View.OVERVIEW
          },
          on: {
            "click": function click($event) {
              return _vm.onStatusClick("good");
            }
          }
        }, [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("GOOD")])])], 1), _c('div', {
          staticClass: "d-flex pr-1 pb-1"
        }, [_c(VChip, {
          attrs: {
            "value": "good",
            "x-small": "",
            "label": "",
            "color": _vm.statusSelected === "check" ? "orange lighten-3" : "orange",
            "dark": "",
            "disabled": _vm.view !== _vm.View.OVERVIEW
          },
          on: {
            "click": function click($event) {
              return _vm.onStatusClick("check");
            }
          }
        }, [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("CHECK")])])], 1), _c('div', {
          staticClass: "d-flex pr-1 pb-1"
        }, [_c(VChip, {
          attrs: {
            "value": "bad",
            "x-small": "",
            "label": "",
            "color": _vm.statusSelected === "bad" ? "red lighten-3" : "red",
            "dark": "",
            "disabled": _vm.view !== _vm.View.OVERVIEW
          },
          on: {
            "click": function click($event) {
              return _vm.onStatusClick("bad");
            }
          }
        }, [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("BAD")])])], 1), _c('div', {
          staticClass: "d-flex pr-1 pb-1"
        }, [_c(VBtn, {
          attrs: {
            "x-small": "",
            "icon": ""
          },
          on: {
            "click": _vm.initGridFilters
          }
        }, [_c(VIcon, {
          attrs: {
            "x-small": ""
          }
        }, [_vm._v("fas fa-filter-circle-xmark")])], 1)], 1)], 2)]), _vm.view === _vm.View.OVERVIEW ? _c('SubscriptionsOverviewGrid', {
          attrs: {
            "is-loading": _vm.isLoading,
            "subscriptions": _vm.subscriptionOverview,
            "grid-filters": _vm.gridFilters
          },
          on: {
            "selection-change": _vm.onSelectionChange,
            "row-count-changed": _vm.onRowCountChange
          }
        }) : _vm.view === _vm.View.SUBSCRIPTIONS ? _c('SubscriptionsSubscriptionsGrid', {
          attrs: {
            "is-loading": _vm.isLoading,
            "subscriptions": _vm.subscriptions,
            "grid-filters": _vm.gridFilters
          },
          on: {
            "row-count-changed": _vm.onRowCountChange
          }
        }) : _vm.view === _vm.View.INVOICES ? _c('SubscriptionsInvoiceGrid', {
          attrs: {
            "is-loading": _vm.isLoading,
            "invoices": _vm.invoices,
            "grid-filters": _vm.gridFilters
          },
          on: {
            "row-count-changed": _vm.onRowCountChange
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }, {
      key: "detail",
      fn: function fn() {
        return [_vm.view !== _vm.View.METRICSLICENCES && _vm.selectedRow ? _c('div', {
          staticClass: "detail-wrapper single-select"
        }, [_c(VRow, [_c(VCol, {
          attrs: {
            "lg": "6",
            "md": "6",
            "sm": "12"
          }
        }, [_c(VCard, {
          attrs: {
            "tile": "",
            "flat": ""
          }
        }, [_c(VCardTitle, [_c('span', [_vm._v("Kundeninformationen (readonly)")])]), _c(VCardText, [_c('div', {
          staticClass: "client-information"
        }, [_c(VTextField, {
          staticClass: "mb-2",
          attrs: {
            "label": "cid",
            "value": _vm.selectedRow.cid,
            "hide-details": "",
            "readonly": "",
            "persistent-placeholder": "",
            "placeholder": "-"
          }
        }), _c(VTextField, {
          staticClass: "mb-2",
          attrs: {
            "label": "Praxisname",
            "value": _vm.selectedRow.praxisName,
            "hide-details": "",
            "readonly": "",
            "persistent-placeholder": "",
            "placeholder": "-"
          }
        }), _c(VTextField, {
          staticClass: "mb-2",
          attrs: {
            "label": "Praxis-Kurzname",
            "value": _vm.selectedRow.praxisKurzName,
            "hide-details": "",
            "readonly": "",
            "persistent-placeholder": "",
            "placeholder": "-"
          }
        }), _c(VTextField, {
          staticClass: "mb-2",
          attrs: {
            "label": "Straße",
            "value": _vm.selectedRow.strasse,
            "hide-details": "",
            "readonly": "",
            "persistent-placeholder": "",
            "placeholder": "-"
          }
        }), _c(VTextField, {
          staticClass: "mb-2",
          attrs: {
            "label": "PLZ",
            "value": _vm.selectedRow.plz,
            "hide-details": "",
            "readonly": "",
            "persistent-placeholder": "",
            "placeholder": "-"
          }
        }), _c(VTextField, {
          staticClass: "mb-2",
          attrs: {
            "label": "Ort",
            "value": _vm.selectedRow.stadt,
            "hide-details": "",
            "readonly": "",
            "persistent-placeholder": "",
            "placeholder": "-"
          }
        }), _c(VTextField, {
          staticClass: "mb-2",
          attrs: {
            "label": "Telefon",
            "value": _vm.selectedRow.praxisTelefon,
            "hide-details": "",
            "readonly": "",
            "persistent-placeholder": "",
            "placeholder": "-"
          }
        }), _c(VTextField, {
          staticClass: "mb-2",
          attrs: {
            "label": "E-Mail",
            "value": _vm.selectedRow.praxisEmail,
            "hide-details": "",
            "readonly": "",
            "persistent-placeholder": "",
            "placeholder": "-"
          }
        })], 1)])], 1)], 1), _c(VCol, {
          attrs: {
            "lg": "6",
            "md": "6",
            "sm": "12"
          }
        }, [_c('SubscriptionsStripe', {
          attrs: {
            "selectedRow": _vm.selectedRow
          }
        })], 1)], 1)], 1) : _vm._e()];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };