import { VChip } from 'vuetify/lib/components/VChip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VChip, {
    staticClass: "pl-2 pr-2",
    attrs: {
      "color": _vm.chipConfig.color,
      "text-color": _vm.chipConfig.textColor,
      "x-small": "",
      "label": "",
      "pill": ""
    }
  }, [_vm._v(_vm._s(_vm.chipConfig.label))]);
};
var staticRenderFns = [];
export { render, staticRenderFns };