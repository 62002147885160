import { isNumber, isNil } from 'lodash';
import { formatNumber } from '../formatNumber';

export const euroFilter = {
  filters: {
    euro(value: any, showDecimal = false, fallback = '-'): string {
      if (isNil(value)) {
        return fallback;
      }
      if (isNumber(value)) {
        value += 0.00001;
      }
      return formatNumber(value, { fractionDigits: showDecimal ? 2 : 0, style: 'currency', currency: 'EUR' });
    },
  },
};
