"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createPatientApi = void 0;
function createPatientApi(api) {
    return {
        getPatientInfos: (params) => api.r4cInstance.post(`/patient/getPatientInfos`, params),
        getPatientsNameInfos: (params) => api.r4cInstance.post(`/factoring/fromr4c/patientinfos`, params),
    };
}
exports.createPatientApi = createPatientApi;
