"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createFactoringApi = void 0;
function createFactoringApi(api) {
    return {
        getClientRechenzentren: (r4chost, secretOverride = '', includeVeraltet = false) => api.r4c.get(r4chost, `/factoring/clientrechenzentren`, {
            query: { secret: secretOverride, ...(includeVeraltet ? { includeVeraltet: '1' } : {}) },
        }),
        getPraxisDaten: (secretOverride = '', includeVeraltet = false) => api.r4cDispatch.get(`/factoring/praxisdaten`, {
            query: { secret: secretOverride, ...(includeVeraltet ? { includeVeraltet: '1' } : {}) },
        }),
        updateClientRechenzentren: (r4chost, params) => api.r4c.post(r4chost, `/factoring/clientrechenzentren`, params),
        getNeueAnfrage: (params) => api.r4cInstance.post(`/factoring/neueanfrage`, params),
        getRecentData: () => api.r4cDispatch.get(`/factoring/recentdata`),
        receiveConsentAgreementShared: (params) => api.r4cInstance.post(`/factoringinit/receiveConsentAgreementShared`, params),
        receiveConsentAgreementTakeover: (params) => api.r4cInstance.post(`/factoringinit/receiveConsentAgreementTakeover`, params),
        receiveConsentAgreement: (params) => api.r4cInstance.post(`/factoringinit/receiveConsentAgreement`, params),
        previewEweDocument: (params) => api.r4cInstance.post(`/factoringinit/previewEweDocument`, params, {
            responseType: 'blob',
        }),
        downloadEweDocument: (params) => api.r4cInstance.post(`/factoringinit/downloadEweDocument`, params, {
            responseType: 'blob',
        }),
        // ewe
        saveEwe: (params) => api.r4cInstance.post(`/factoring/ewe`, params),
        eweWiderruf: (params) => api.r4cInstance.post(`/factoring/ewewiderruf`, params),
        eweMitgegeben: (params) => api.r4cInstance.post(`/factoring/ewemitgegeben`, params),
        rzAblehnung: (params) => api.r4cInstance.post(`/factoring/rzablehnung`, params),
        keinRZAnbieten: (params) => api.r4cInstance.post(`/factoring/keinrzanbieten`, params),
        eweLoeschen: (params) => api.r4cInstance.post(`/factoring/eweloeschen/${params.eweid}`, params),
        ewePerMail: (params) => api.r4cInstance.post(`/factoring/ewe/ewePerMail`, params),
        setTabletEweTask: (params) => api.r4cInstance.post(`/factoring/ewe/tablet/task`, params),
        cancelTablet: (tabletId, info) => api.r4cInstance.delete(`/factoring/ewe/tablet/task/${tabletId}`, {
            info,
        }),
        initializeDesktopR4c: () => api.r4cDispatch.post(`/factoring/initializeDesktopR4c`),
        getFileUploads: () => api.r4cDispatch.get(`/factoring/fileUploads`),
    };
}
exports.createFactoringApi = createFactoringApi;
