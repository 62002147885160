import { VChip } from 'vuetify/lib/components/VChip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "d-flex py-2"
  }, [_c(VChip, {
    attrs: {
      "color": _vm.aboStatusColor,
      "x-small": "",
      "label": "",
      "dark": ""
    }
  }, [_c('div', {
    staticClass: "d-flex align-baseline"
  }, [_c('span', {
    staticClass: "abo-status font-weight-bold"
  }, [_vm._v(_vm._s(_vm.aboStatus))]), _vm.reason ? _c('span', {
    staticClass: "pl-1"
  }, [_vm._v(_vm._s(_vm.reason))]) : _vm._e()])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };