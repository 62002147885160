import { MetricsApi } from '../../../base';
import { installRoseImageDirective } from './roseImageDirective';
import { installModelBindRouteQueryDirective, IViewStateProvider } from './v-model-bind-view-state';
import { createViewStateProviderFromRouter } from './viewStateProviderFromRouter';

export function registerRoseGlobalDirectives({
  Vue,
  router,
  viewStateProvider,
  metricsApi,
}: {
  Vue: any;
  router: any;
  viewStateProvider?: IViewStateProvider;
  metricsApi?: MetricsApi;
}) {
  if (!viewStateProvider) {
    viewStateProvider = createViewStateProviderFromRouter(router);
  }
  installModelBindRouteQueryDirective(Vue, viewStateProvider);
  if (metricsApi) {
    installRoseImageDirective(Vue, metricsApi);
  }
}
