"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDoctosyncApi = void 0;
function createDoctosyncApi(roseApi) {
    return {
        extendStateGetter: (extendedStateGetter) => createDoctosyncApi(roseApi.extendStateGetter(extendedStateGetter)),
        getUnmatchedPatients: () => roseApi.doctosync.get(`/api/unmatchedpatients`),
        addPatid: (extid, patid) => roseApi.doctosync.post(`/api/addpatid`, { extid, patid }),
        reset: (cid, hard = false) => roseApi.doctosync.post(`/api/reset`, { hard }, { headers: { 'rose-client-id': cid } }),
        getMeta: (cid) => roseApi.doctosync.get(`/api/meta`, { headers: { 'rose-client-id': cid } }),
        getProblems: (cid) => roseApi.doctosync.get(`/api/problems`, { headers: { 'rose-client-id': cid } }),
    };
}
exports.createDoctosyncApi = createDoctosyncApi;
