import deDE from 'numbro/languages/de-DE';

export function germanLocale() {
  const de = {
    delimiters: {
      thousands: '.',
      decimal: ',',
    },
    abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't',
    },
    ordinal: () => '.',
    currency: {
      symbol: '€',
    },
  };

  return de;
}

export function registerNumbroLocale(n: any) {
  n.registerLanguage(deDE);
  n.setLanguage('de-DE');
  console.log('registerNumbroLocale', n.language(), n);
}
