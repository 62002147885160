import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';

import _defineProperty from "/builds/rose-metrics/rose-metrics/admin/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.clientSettings ? _c(VCard, {
    staticClass: "database-wrapper",
    attrs: {
      "outlined": ""
    }
  }, [_c(VCardTitle, [_c('a', {
    staticClass: "card-title",
    attrs: {
      "href": _vm.connectorCheckUrl,
      "target": "_blank"
    }
  }, [_vm._v("bot/pi/connector")])]), _c(VCardText, [_vm.bot.postgresScan && _vm.bot.postgresScan.length > 0 ? _c('div', {
    staticClass: "found-instances"
  }, _vm._l(_vm.bot.postgresScan, function (i, idx) {
    return _c('div', {
      staticClass: "instance"
    }, [_c('div', {
      staticClass: "idx"
    }, [_vm._v(_vm._s(idx + 1) + ":")]), _c('div', {
      staticClass: "host",
      attrs: {
        "title": "host:port"
      }
    }, [_vm._v(_vm._s(i.host) + ":" + _vm._s(i.port))]), _c('div', {
      staticClass: "database",
      attrs: {
        "title": "database"
      }
    }, [_vm._v(_vm._s(i.database) + " @ " + _vm._s(_vm._f("dbVersionShort")(i.dbversion)))]), _c('div', {
      staticClass: "praxis",
      attrs: {
        "title": "praxis"
      }
    }, [_vm._v(_vm._s(i.praxis))]), i.praxisInfo ? _c('div', {
      directives: [{
        name: "clipboard",
        rawName: "v-clipboard:copy",
        value: i.praxisInfo.license,
        expression: "i.praxisInfo.license",
        arg: "copy"
      }, {
        name: "clipboard",
        rawName: "v-clipboard:success",
        value: _vm.copySuccess,
        expression: "copySuccess",
        arg: "success"
      }, {
        name: "clipboard",
        rawName: "v-clipboard:error",
        value: _vm.copyError,
        expression: "copyError",
        arg: "error"
      }],
      staticClass: "license",
      attrs: {
        "title": "license"
      }
    }, [_vm._v("l: " + _vm._s(i.praxisInfo.license))]) : _vm._e(), i.praxisInfo ? _c('div', {
      directives: [{
        name: "clipboard",
        rawName: "v-clipboard:copy",
        value: i.praxisInfo.serialnr,
        expression: "i.praxisInfo.serialnr",
        arg: "copy"
      }, {
        name: "clipboard",
        rawName: "v-clipboard:success",
        value: _vm.copySuccess,
        expression: "copySuccess",
        arg: "success"
      }, {
        name: "clipboard",
        rawName: "v-clipboard:error",
        value: _vm.copyError,
        expression: "copyError",
        arg: "error"
      }],
      staticClass: "serialnr",
      attrs: {
        "title": "serialnr"
      }
    }, [_vm._v("s/n: " + _vm._s(i.praxisInfo.serialnr))]) : _vm._e()]);
  }), 0) : _vm.clientSettings.pvs === "charly" ? _c('div', {
    staticClass: "no-found-instances"
  }, [_vm._v("Keine charly-Instanzen gefunden")]) : _vm._e(), _c('div', {
    staticClass: "main-grid"
  }, [!_vm.isSolutioTeam ? _c('div', {
    staticClass: "input-fields-row"
  }, [_c('div', {
    staticClass: "main-label"
  }, [_vm._v("pvs")]), _c('div', {
    staticClass: "inputs d-flex justify-space-between"
  }, [_c(VBtnToggle, {
    attrs: {
      "mandatory": "",
      "dense": ""
    },
    model: {
      value: _vm.clientSettings.pvs,
      callback: function callback($$v) {
        _vm.$set(_vm.clientSettings, "pvs", $$v);
      },
      expression: "clientSettings.pvs"
    }
  }, [_c(VBtn, {
    attrs: {
      "value": "charly"
    }
  }, [_vm._v("charly")]), _c(VBtn, {
    attrs: {
      "value": "z1v2"
    }
  }, [_vm._v("z1v2")]), _c(VBtn, {
    attrs: {
      "value": "evident"
    }
  }, [_vm._v("evident")]), _c(VBtn, {
    attrs: {
      "value": "claire"
    }
  }, [_vm._v("claire")]), _c(VBtn, {
    attrs: {
      "value": "dentport"
    }
  }, [_vm._v("dentport")])], 1), _c(VBtn, {
    staticClass: "ml-2",
    attrs: {
      "depressed": "",
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        return _vm.setDBDefaults(_vm.clientSettings.pvs);
      }
    }
  }, [_vm._v("set db defaults")])], 1)]) : _vm._e(), _c('div', {
    staticClass: "input-fields-row"
  }, [_c('div', {
    staticClass: "main-label"
  }, [_c(VIcon, {
    attrs: {
      "small": ""
    }
  }, [_vm._v("fa fa-database")])], 1), _c('div', {
    staticClass: "db-input-row"
  }, [_c('div', {
    staticClass: "input-label"
  }, [_vm._v("host:")]), _c(VTextField, {
    attrs: {
      "hide-details": "",
      "dense": ""
    },
    model: {
      value: _vm.clientSettings.db.host,
      callback: function callback($$v) {
        _vm.$set(_vm.clientSettings.db, "host", $$v);
      },
      expression: "clientSettings.db.host"
    }
  }), _c('div', {
    staticClass: "input-label"
  }, [_vm._v("port:")]), _c(VTextField, {
    attrs: {
      "hide-details": "",
      "dense": ""
    },
    model: {
      value: _vm.clientSettings.db.port,
      callback: function callback($$v) {
        _vm.$set(_vm.clientSettings.db, "port", $$v);
      },
      expression: "clientSettings.db.port"
    }
  }), _c('div', {
    staticClass: "input-label"
  }, [_vm._v("benutzer:")]), _c(VTextField, {
    attrs: {
      "hide-details": "",
      "dense": ""
    },
    model: {
      value: _vm.clientSettings.db.user,
      callback: function callback($$v) {
        _vm.$set(_vm.clientSettings.db, "user", $$v);
      },
      expression: "clientSettings.db.user"
    }
  }), _c('div', {
    staticClass: "input-label"
  }, [_vm._v("passwort:")]), _c(VTextField, {
    attrs: {
      "hide-details": "",
      "dense": ""
    },
    model: {
      value: _vm.clientSettings.db.pass,
      callback: function callback($$v) {
        _vm.$set(_vm.clientSettings.db, "pass", $$v);
      },
      expression: "clientSettings.db.pass"
    }
  }), _c('div', {
    staticClass: "input-label"
  }, [_vm._v("readonly:")]), _c(VBtnToggle, {
    attrs: {
      "mandatory": "",
      "dense": "",
      "color": _vm.clientSettings.db.userIsReadOnlyAlready ? "error" : "info"
    },
    model: {
      value: _vm.clientSettings.db.userIsReadOnlyAlready,
      callback: function callback($$v) {
        _vm.$set(_vm.clientSettings.db, "userIsReadOnlyAlready", $$v);
      },
      expression: "clientSettings.db.userIsReadOnlyAlready"
    }
  }, [_c(VBtn, {
    attrs: {
      "value": false,
      "x-small": ""
    }
  }, [_vm._v("readwrite")]), _c(VBtn, {
    attrs: {
      "value": true,
      "x-small": ""
    }
  }, [_vm._v("readonly")])], 1), _vm.clientSettings.pvs === _vm.pvsEnum.EVIDENT ? _c('div', {
    staticClass: "input-label"
  }, [_vm._v("db-base-path:")]) : _c('div', {
    staticClass: "input-label"
  }, [_vm._v("db-name:")]), _c(VTextField, {
    attrs: {
      "hide-details": "",
      "dense": ""
    },
    model: {
      value: _vm.clientSettings.db.db,
      callback: function callback($$v) {
        _vm.$set(_vm.clientSettings.db, "db", $$v);
      },
      expression: "clientSettings.db.db"
    }
  }), _vm.clientSettings.pvs === _vm.pvsEnum.CLAIRE ? _c('div', {
    staticClass: "input-label"
  }, [_vm._v("clinicId:")]) : _vm._e(), _vm.clientSettings.pvs === _vm.pvsEnum.CLAIRE ? _c(VTextField, {
    attrs: {
      "hide-details": "",
      "dense": ""
    },
    model: {
      value: _vm.clientSettings.db.extid,
      callback: function callback($$v) {
        _vm.$set(_vm.clientSettings.db, "extid", $$v);
      },
      expression: "clientSettings.db.extid"
    }
  }) : _vm._e(), _vm.clientSettings.pvs === _vm.pvsEnum.Z1V2 || _vm.clientSettings.pvs === _vm.pvsEnum.CLAIRE ? _c('div', {
    staticClass: "input-label"
  }, [_vm._v("tls:")]) : _vm._e(), _vm.clientSettings.pvs === _vm.pvsEnum.Z1V2 || _vm.clientSettings.pvs === _vm.pvsEnum.CLAIRE ? _c(VBtnToggle, {
    attrs: {
      "mandatory": "",
      "dense": "",
      "color": _vm.clientSettings.db.disableEncryption ? "error" : "info"
    },
    model: {
      value: _vm.clientSettings.db.disableEncryption,
      callback: function callback($$v) {
        _vm.$set(_vm.clientSettings.db, "disableEncryption", $$v);
      },
      expression: "clientSettings.db.disableEncryption"
    }
  }, [_c(VBtn, {
    attrs: {
      "value": false,
      "x-small": ""
    }
  }, [_vm._v("encrypted")]), _c(VBtn, {
    attrs: {
      "value": true,
      "x-small": ""
    }
  }, [_vm._v("disabled")])], 1) : _vm._e()], 1)]), [_vm.pvsEnum.Z1V2, _vm.pvsEnum.EVIDENT].includes(_vm.clientSettings.pvs) ? _c('div', {
    staticClass: "input-fields-row"
  }, [_c('div', {
    staticClass: "main-label"
  }), _vm.clientSettings.pvs === _vm.pvsEnum.Z1V2 ? _c('div', {
    staticClass: "db-input-row"
  }, [_c('div', {
    staticClass: "input-label"
  }, [_vm._v("mandanten:")]), _c(VAutocomplete, {
    attrs: {
      "dense": "",
      "loading": !_vm.z1Mandanten,
      "items": _vm.z1Mandanten,
      "small-chips": "",
      "deletable-chips": "",
      "item-text": _vm.mandantenText,
      "item-value": "extid",
      "multiple": "",
      "hide-details": ""
    },
    model: {
      value: _vm.clientSettings.db.mandanten,
      callback: function callback($$v) {
        _vm.$set(_vm.clientSettings.db, "mandanten", $$v);
      },
      expression: "clientSettings.db.mandanten"
    }
  }), _c('div', {
    staticClass: "input-label"
  }, [_vm._v("timer:")]), _c(VBtnToggle, {
    attrs: {
      "mandatory": "",
      "dense": "",
      "color": _vm.clientSettings.db.ignoreTimer ? "error" : "info"
    },
    model: {
      value: _vm.clientSettings.db.ignoreTimer,
      callback: function callback($$v) {
        _vm.$set(_vm.clientSettings.db, "ignoreTimer", $$v);
      },
      expression: "clientSettings.db.ignoreTimer"
    }
  }, [_c(VBtn, {
    attrs: {
      "value": false,
      "x-small": ""
    }
  }, [_vm._v("active")]), _c(VBtn, {
    attrs: {
      "value": true,
      "x-small": ""
    }
  }, [_vm._v("ignore")])], 1), _c('div', {
    staticClass: "input-label"
  }, [_vm._v("timer-db-host:")]), _c(VTextField, {
    attrs: {
      "disabled": _vm.clientSettings.db.ignoreTimer,
      "placeholder": _vm.clientSettings.db.host,
      "hide-details": "",
      "dense": ""
    },
    model: {
      value: _vm.clientSettings.db.termineDB.host,
      callback: function callback($$v) {
        _vm.$set(_vm.clientSettings.db.termineDB, "host", $$v);
      },
      expression: "clientSettings.db.termineDB.host"
    }
  }), _c('div', {
    staticClass: "input-label"
  }, [_vm._v("timer-db-port:")]), _c(VTextField, {
    attrs: {
      "disabled": _vm.clientSettings.db.ignoreTimer,
      "placeholder": "1433",
      "hide-details": "",
      "dense": ""
    },
    model: {
      value: _vm.clientSettings.db.termineDB.port,
      callback: function callback($$v) {
        _vm.$set(_vm.clientSettings.db.termineDB, "port", $$v);
      },
      expression: "clientSettings.db.termineDB.port"
    }
  }), _c('div', {
    staticClass: "input-label"
  }, [_vm._v("timer-db-benutzer:")]), _c(VTextField, {
    attrs: {
      "disabled": _vm.clientSettings.db.ignoreTimer,
      "placeholder": _vm.clientSettings.db.user,
      "hide-details": "",
      "dense": ""
    },
    model: {
      value: _vm.clientSettings.db.termineDB.user,
      callback: function callback($$v) {
        _vm.$set(_vm.clientSettings.db.termineDB, "user", $$v);
      },
      expression: "clientSettings.db.termineDB.user"
    }
  }), _c('div', {
    staticClass: "input-label"
  }, [_vm._v("timer-db-passwort:")]), _c(VTextField, {
    attrs: {
      "placeholder": _vm.clientSettings.db.pass,
      "disabled": _vm.clientSettings.db.ignoreTimer,
      "hide-details": "",
      "dense": ""
    },
    model: {
      value: _vm.clientSettings.db.termineDB.pass,
      callback: function callback($$v) {
        _vm.$set(_vm.clientSettings.db.termineDB, "pass", $$v);
      },
      expression: "clientSettings.db.termineDB.pass"
    }
  }), _c('div', {
    staticClass: "input-label"
  }, [_vm._v("timer-db-name:")]), _c(VTextField, {
    attrs: {
      "placeholder": "timer",
      "disabled": _vm.clientSettings.db.ignoreTimer,
      "hide-details": "",
      "dense": ""
    },
    model: {
      value: _vm.clientSettings.db.termineDB.db,
      callback: function callback($$v) {
        _vm.$set(_vm.clientSettings.db.termineDB, "db", $$v);
      },
      expression: "clientSettings.db.termineDB.db"
    }
  })], 1) : _vm.clientSettings.pvs === _vm.pvsEnum.EVIDENT ? _c('div', {
    staticClass: "db-input-row"
  }, [_c('div', {
    staticClass: "input-label"
  }, [_vm._v("praxis")]), _c(VAutocomplete, {
    staticClass: "mr-4",
    attrs: {
      "dense": "",
      "loading": !_vm.evidentPraxen,
      "items": _vm.evidentPraxen,
      "small-chips": "",
      "item-text": _vm.mandantenText,
      "item-value": "extid",
      "hide-details": ""
    },
    model: {
      value: _vm.clientSettings.db.praxis,
      callback: function callback($$v) {
        _vm.$set(_vm.clientSettings.db, "praxis", $$v);
      },
      expression: "clientSettings.db.praxis"
    }
  })], 1) : _vm._e()]) : _vm._e(), _vm.bot ? _c('div', {
    staticClass: "input-fields-row"
  }, [_c('div', {
    staticClass: "main-label"
  }, [_c(VIcon, {
    attrs: {
      "small": ""
    }
  }, [_vm._v("fa fa-router")])], 1), _c('div', {
    staticClass: "db-input-row"
  }, [_c('div', {
    staticClass: "input-label"
  }, [_vm._v("ssh-tunnel-port")]), _c(VTextField, {
    attrs: {
      "disabled": _vm.hasNoConnector,
      "hide-details": "",
      "dense": ""
    },
    model: {
      value: _vm.bot.sshPort,
      callback: function callback($$v) {
        _vm.$set(_vm.bot, "sshPort", $$v);
      },
      expression: "bot.sshPort"
    }
  }), _c('div', {
    staticClass: "input-label"
  }, [_vm._v("ssh-server-port")]), _c(VTextField, {
    attrs: {
      "disabled": _vm.hasNoConnector,
      "hide-details": "",
      "dense": ""
    },
    model: {
      value: _vm.bot.sshServerPort,
      callback: function callback($$v) {
        _vm.$set(_vm.bot, "sshServerPort", $$v);
      },
      expression: "bot.sshServerPort"
    }
  }), _c('div', {
    staticClass: "input-label"
  }, [_vm._v("ssh-alias")]), _c('div', {
    staticClass: "d-flex"
  }, [_c(VTextField, {
    attrs: {
      "disabled": _vm.hasNoConnector,
      "hide-details": "",
      "dense": ""
    },
    model: {
      value: _vm.bot.alias,
      callback: function callback($$v) {
        _vm.$set(_vm.bot, "alias", $$v);
      },
      expression: "bot.alias"
    }
  }), _c(VBtn, {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard",
      value: _vm.copySSHInfo(),
      expression: "copySSHInfo()"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.copySuccess,
      expression: "copySuccess",
      arg: "success"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:error",
      value: _vm.copyError,
      expression: "copyError",
      arg: "error"
    }],
    staticClass: "ml-2",
    attrs: {
      "small": "",
      "color": "purple",
      "dark": "",
      "depressed": ""
    }
  }, [_vm._v("SSH-Cfg")])], 1), _c('div', {
    staticClass: "input-label"
  }, [_vm._v("linux version")]), _c(VTextField, {
    attrs: {
      "disabled": _vm.hasNoConnector,
      "hide-details": "",
      "dense": "",
      "readonly": ""
    },
    model: {
      value: _vm.bot.linuxVersion,
      callback: function callback($$v) {
        _vm.$set(_vm.bot, "linuxVersion", $$v);
      },
      expression: "bot.linuxVersion"
    }
  }), _c('div', {
    staticClass: "input-label"
  }, [_vm._v("node version")]), _c(VTextField, {
    attrs: {
      "disabled": _vm.hasNoConnector,
      "hide-details": "",
      "dense": "",
      "readonly": ""
    },
    model: {
      value: _vm.bot.nodeVersion,
      callback: function callback($$v) {
        _vm.$set(_vm.bot, "nodeVersion", $$v);
      },
      expression: "bot.nodeVersion"
    }
  }), _c('div', {
    staticClass: "input-label"
  }, [_vm._v("bot-id")]), _c(VTextField, {
    attrs: _defineProperty(_defineProperty(_defineProperty({
      "disabled": _vm.hasNoConnector,
      "readonly": ""
    }, "disabled", ""), "hide-details", ""), "dense", ""),
    model: {
      value: _vm.bot.botId,
      callback: function callback($$v) {
        _vm.$set(_vm.bot, "botId", $$v);
      },
      expression: "bot.botId"
    }
  }), _c('div', {
    staticClass: "input-label"
  }, [_vm._v("ip-intern:")]), _c(VTextField, {
    attrs: _defineProperty(_defineProperty(_defineProperty({
      "disabled": _vm.hasNoConnector,
      "readonly": ""
    }, "disabled", ""), "hide-details", ""), "dense", ""),
    model: {
      value: _vm.bot.ipInternal,
      callback: function callback($$v) {
        _vm.$set(_vm.bot, "ipInternal", $$v);
      },
      expression: "bot.ipInternal"
    }
  }), _c('div', {
    staticClass: "input-label"
  }, [_vm._v("ip-extern:")]), _c('div', {
    staticClass: "field-and-button d-flex"
  }, [_c(VTextField, {
    attrs: _defineProperty(_defineProperty(_defineProperty({
      "disabled": _vm.hasNoConnector,
      "readonly": ""
    }, "disabled", ""), "hide-details", ""), "dense", ""),
    model: {
      value: _vm.bot.ipExternal,
      callback: function callback($$v) {
        _vm.$set(_vm.bot, "ipExternal", $$v);
      },
      expression: "bot.ipExternal"
    }
  }), _c(VBtn, {
    attrs: {
      "small": "",
      "color": "purple",
      "depressed": "",
      "dark": ""
    },
    on: {
      "click": _vm.updateExternalIP
    }
  }, [_c(VIcon, {
    attrs: {
      "small": ""
    }
  }, [_vm._v("fa-rotate")])], 1)], 1), _c('div', {
    staticClass: "input-label"
  }, [_vm._v("subnetmask")]), _c(VTextField, {
    attrs: _defineProperty(_defineProperty(_defineProperty({
      "disabled": _vm.hasNoConnector,
      "readonly": ""
    }, "disabled", ""), "hide-details", ""), "dense", ""),
    model: {
      value: _vm.bot.subnetMask,
      callback: function callback($$v) {
        _vm.$set(_vm.bot, "subnetMask", $$v);
      },
      expression: "bot.subnetMask"
    }
  }), _c('div', {
    staticClass: "notting"
  }), _c('div', {
    staticClass: "notting"
  }), _c('div', {
    staticClass: "input-label"
  }, [_vm._v("aktiv (darf verbinden)")]), _c(VBtnToggle, {
    attrs: {
      "mandatory": "",
      "dense": "",
      "color": _vm.bot.active ? "info" : "error"
    },
    model: {
      value: _vm.bot.active,
      callback: function callback($$v) {
        _vm.$set(_vm.bot, "active", $$v);
      },
      expression: "bot.active"
    }
  }, [_c(VBtn, {
    attrs: {
      "value": true,
      "x-small": ""
    }
  }, [_vm._v("active")]), _c(VBtn, {
    attrs: {
      "value": false,
      "x-small": ""
    }
  }, [_vm._v("inactive")])], 1), _c('div', {
    staticClass: "input-label"
  }, [_vm._v("monitor (führt import aus)")]), _c(VBtnToggle, {
    attrs: {
      "mandatory": "",
      "dense": "",
      "color": _vm.bot.monitor ? "info" : "error"
    },
    model: {
      value: _vm.bot.monitor,
      callback: function callback($$v) {
        _vm.$set(_vm.bot, "monitor", $$v);
      },
      expression: "bot.monitor"
    }
  }, [_c(VBtn, {
    attrs: {
      "value": true,
      "x-small": ""
    }
  }, [_vm._v("active")]), _c(VBtn, {
    attrs: {
      "value": false,
      "x-small": ""
    }
  }, [_vm._v("inactive")])], 1), _c('div', {
    staticClass: "input-label"
  }, [_vm._v("incremental import")]), _c(VBtnToggle, {
    attrs: {
      "mandatory": "",
      "dense": "",
      "color": _vm.clientSettings.datensammlung.deactivateIncImport ? "error" : "info"
    },
    model: {
      value: _vm.clientSettings.datensammlung.deactivateIncImport,
      callback: function callback($$v) {
        _vm.$set(_vm.clientSettings.datensammlung, "deactivateIncImport", $$v);
      },
      expression: "clientSettings.datensammlung.deactivateIncImport"
    }
  }, [_c(VBtn, {
    attrs: {
      "value": false,
      "x-small": ""
    }
  }, [_vm._v("active")]), _c(VBtn, {
    attrs: {
      "value": true,
      "x-small": ""
    }
  }, [_vm._v("inactive")])], 1), _c('div', {
    staticClass: "input-label"
  }, [_vm._v("full import")]), _c(VBtnToggle, {
    attrs: {
      "mandatory": "",
      "dense": "",
      "color": _vm.clientSettings.datensammlung.deactivateFullImport ? "error" : "info"
    },
    model: {
      value: _vm.clientSettings.datensammlung.deactivateFullImport,
      callback: function callback($$v) {
        _vm.$set(_vm.clientSettings.datensammlung, "deactivateFullImport", $$v);
      },
      expression: "clientSettings.datensammlung.deactivateFullImport"
    }
  }, [_c(VBtn, {
    attrs: {
      "value": false,
      "x-small": ""
    }
  }, [_vm._v("active")]), _c(VBtn, {
    attrs: {
      "value": true,
      "x-small": ""
    }
  }, [_vm._v("inactive")])], 1)], 1)]) : _vm._e()]), _c('BotActionsComponent', {
    staticClass: "mb-2",
    attrs: {
      "clientSettings": _vm.clientSettings,
      "cid": _vm.clientSettings.cid
    }
  }), _c('div', {
    staticClass: "save-button"
  }, [_c(VBtn, {
    attrs: {
      "color": "success",
      "block": "",
      "x-small": ""
    },
    on: {
      "click": _vm.updateSettings
    }
  }, [_vm._v("Speichern")])], 1)], 1)], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };