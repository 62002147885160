"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createUploadTaskApi = void 0;
function createUploadTaskApi(api) {
    return {
        assignTabletUploadTask: (params) => api.r4cInstance.post(`/tablet/task/upload`, params),
        uploadFile: (params) => api.r4cInstance.post(`/tablet/task/uploadFiles`, params),
        uploadFilesFromDesktopR4c: (params) => api.r4cInstance.post(`/tablet/task/uploadFilesFromDestopR4c`, params),
    };
}
exports.createUploadTaskApi = createUploadTaskApi;
