var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-popover', {
    staticClass: "licences-info",
    "class": {
      problem: _vm.licenceMissmatch
    },
    attrs: {
      "trigger": "click",
      "autoHide": true
    },
    scopedSlots: _vm._u([{
      key: "popover",
      fn: function fn() {
        var _vm$ab, _vm$ab2, _vm$ab3, _vm$ab4;
        return [_c('div', {
          staticClass: "licences"
        }, [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm.title))]), _c('div', {
          staticClass: "za"
        }, [_vm._v("#za: " + _vm._s((_vm$ab = _vm.ab) === null || _vm$ab === void 0 ? void 0 : _vm$ab.za))]), _c('div', {
          staticClass: "pzr"
        }, [_vm._v("#pzr: " + _vm._s((_vm$ab2 = _vm.ab) === null || _vm$ab2 === void 0 ? void 0 : _vm$ab2.pzr))]), _c('div', {
          staticClass: "billable"
        }, [_vm._v("#billable:" + _vm._s((_vm$ab3 = _vm.ab) === null || _vm$ab3 === void 0 ? void 0 : _vm$ab3.billableRounded))]), _c('div', {
          staticClass: "stripe"
        }, [_vm._v("#stripe: " + _vm._s(_vm.stripe))]), _c('div', {
          staticClass: "behandler-details"
        }, [_c('span', [_vm._v("Behandler-Details")]), _vm._l((_vm$ab4 = _vm.ab) === null || _vm$ab4 === void 0 || (_vm$ab4 = _vm$ab4.raw) === null || _vm$ab4 === void 0 ? void 0 : _vm$ab4.behandlerinfo, function (bi) {
          return _c('div', {
            staticClass: "behandler"
          }, [_c('div', {
            staticClass: "b-id"
          }, [_vm._v(_vm._s(bi.behandler))]), _c('div', {
            staticClass: "b-typ"
          }, [_vm._v(_vm._s(bi.ltyp))]), _c('div', {
            staticClass: "b-hon"
          }, [_vm._v(_vm._s(_vm._f("euro")(bi.honorar)))]), _c('div', {
            staticClass: "b-t3"
          }, [_vm._v(_vm._s(_vm.joinLeistungen(bi.t3)))])]);
        })], 2)])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "linfo-short"
  }, [_vm._v(_vm._s(_vm.licenceInfo))])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };