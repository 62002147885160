import { AxiosError, AxiosInstance, isAxiosError } from 'axios';

import { AuthRequestStateGetter, RoseApi, createRoseApiWithAxios, truncateString } from '../../../base';
import {
  collectorUrl,
  getDoctosyncUrl,
  getR4cApiUrl,
  getSaverUrl,
  getUiUrl,
  metricsUrl,
  r4cDispatchUrl,
} from '../config';
import { format as _formatAxiosError } from '@redtea/format-axios-error';

export type AxiosErrorExport = AxiosError;

export function createRoseApiCommon({
  stateGetter,
  installInterceptors,
}: {
  stateGetter: AuthRequestStateGetter;
  installInterceptors?: ((axiosInstance: AxiosInstance) => void)[];
}): RoseApi {
  return createRoseApiWithAxios({
    stateGetter,
    serviceBaseUrls: {
      metrics: metricsUrl,
      collector: collectorUrl,
      r4cDispatcher: r4cDispatchUrl,
      saver: getSaverUrl(),
      doctosync: getDoctosyncUrl(),
      ui: getUiUrl(),
      getR4cServerUrl: getR4cApiUrl,
    },
    installInterceptors: [...(installInterceptors || []), axiosParseBinaryErrorResponseInterceptor],
  });
}

// axios helpers

export function axiosParseBinaryErrorResponseInterceptor(axiosInstance: AxiosInstance) {
  axiosInstance.interceptors.response.use(
    response => response,
    async (error: AxiosError) => {
      if (error.response && error.response.data instanceof Blob) {
        try {
          const text = await error.response.data.text();
          const json = JSON.parse(text);
          error.response.data = json;
        } catch (e) {
          console.warn('Error parsing error response data as JSON', e, error);
        }
      }
      throw error;
    },
  );
}

declare module 'axios' {
  export interface AxiosRequestConfig {
    metadata?: {
      startTime: Date;
    };
  }
}

export function installRequestTimingInterceptor(
  axiosInstance: AxiosInstance,
  { requestFinished, requestStarted }: { requestStarted: () => void; requestFinished: (duration: number) => void },
) {
  axiosInstance.interceptors.request.use(config => {
    config.metadata = { ...config.metadata, startTime: new Date() };
    requestStarted();
    return config;
  });

  const processTiming = (config: any) => {
    const metadata = config?.metadata;
    if (metadata) {
      const duration = new Date().getTime() - metadata.startTime.getTime();
      requestFinished(duration);
    }
  };

  axiosInstance.interceptors.response.use(
    response => {
      processTiming(response.config);
      return response;
    },
    error => {
      processTiming(error.config);
      return Promise.reject(error);
    },
  );
}

export function formatApiErrorJson(err: any) {
  if (isAxiosError(err)) {
    const axiosErr = _formatAxiosError(err);

    // get auth header, extract jwt and add it
    const authHeader: string = axiosErr.config.headers?.Authorization;
    const jwtToken = authHeader?.split(' ')[1];
    let jwt: any;
    if (jwtToken) {
      try {
        jwt = extractPayloadFromToken(jwtToken);
      } catch (e) {
        console.error('Could not extract jwt from auth header', e);
      }
    }

    // truncate all headers
    for (const key of Object.keys(axiosErr.config.headers || [])) {
      if (axiosErr.config.headers && axiosErr.config.headers[key]) {
        axiosErr.config.headers[key] = truncateString(axiosErr.config.headers[key], 50);
      }
    }

    return { ...axiosErr, jwt };
  }

  return String(err);
}

export function formatApiError(err: any) {
  return JSON.stringify(formatApiErrorJson(err));
}

export function extractPayloadFromToken(token: string) {
  const base64Url: string = token.split('.')[1];
  const base64: string = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
}
