/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./CharlyUploads.vue?vue&type=template&id=54830a21&scoped=true&lang=pug"
import script from "./CharlyUploads.vue?vue&type=script&lang=ts"
export * from "./CharlyUploads.vue?vue&type=script&lang=ts"
import style0 from "./CharlyUploads.vue?vue&type=style&index=0&id=54830a21&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54830a21",
  null
  
)

export default component.exports