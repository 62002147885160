import { IPercentage, FEATURES } from '@/../../types';

export interface ITableFilterGroup {
  name: string;
  prefix: string;
  filters: ITableFilter[];
  class?: string;
  fg?: string;
  bg?: string;
  dark?: boolean;
}

export interface ITableFilter {
  name: string;
  d: IPercentage; // value to display
  v2?: string; // second optionbal value to display
  filterExpression?: string;
}

/* Subscription Grid Filters */

export enum DateFilter {
  NONE = 'none',
  START = 'start',
  END = 'end',
  RUNNING = 'running',
}

export interface IDateFilter {
  type: DateFilter;
  startDate: string;
  endDate: string;
}

export interface ISubscriptionGridFilters {
  feature?: FEATURES;
  status?: string;
  dateRange: IDateFilter;
}
