import _toConsumableArray from "/builds/rose-metrics/rose-metrics/admin/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _createForOfIteratorHelper from "/builds/rose-metrics/rose-metrics/admin/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.fill.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import { filter, first, isEmpty, map, sumBy } from 'lodash';
import { defineComponent } from 'vue';
import { boldCellStyle, createCell, createEmptyWorkSheet, finalizeSimpleXLSXWithMetadata } from '@rose/base';
import { iosVersion } from '@rose/common-ui';
import { createWorkBook, saveXLSX } from '@/services/xls';
import { columnCellRendererComponents } from '@/components/columns/cellComponents';
import { createMonths, metricsLicenceCheckColumns } from '@/components/columns/metricsLicenceCheck';
import { globalSearchStore } from '@/state/globalSearch.state';
import { Units } from '../../../types/js';
export default defineComponent({
  name: 'MetricsLicenceCheckGrid',
  components: Object.assign({}, columnCellRendererComponents),
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },
    licenceData: {
      type: Array,
      required: true
    }
  },
  data: function data() {
    return {
      columnDefs: metricsLicenceCheckColumns(!!iosVersion),
      gridOptions: {},
      gridApi: {},
      selectedRow: null
    };
  },
  computed: {
    filteredLicenceData: function filteredLicenceData() {
      if (!this.licenceData) {
        return null;
      }
      var searchInput = globalSearchStore.searchInput;
      if (searchInput) {
        return filter(this.licenceData, function (row) {
          return row.praxisname.toLowerCase().includes(searchInput.toLowerCase()) || row.cid.toLowerCase().includes(searchInput.toLowerCase());
        });
      }
      return this.licenceData;
    },
    summaryRow: function summaryRow() {
      var _this = this;
      if (!this.filteredLicenceData) {
        return [];
      }
      var sr = {
        isSummaryRow: true,
        cid: '',
        praxisname: '',
        stripeCustomerId: '',
        started: ''
      };
      var _iterator = _createForOfIteratorHelper(createMonths()),
        _step;
      try {
        var _loop = function _loop() {
          var m = _step.value;
          sr[m] = sumBy(_this.filteredLicenceData, function (x) {
            var _a, _b;
            return Math.abs(((_a = x[m]) === null || _a === void 0 ? void 0 : _a.roseusage) - ((_b = x[m]) === null || _b === void 0 ? void 0 : _b.stripeusage));
          });
        };
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          _loop();
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      console.log('summaryRow', sr);
      return [sr];
    }
  },
  watch: {
    isLoading: function isLoading() {
      if (isEmpty(this.gridApi)) return;
      if (this.isLoading) {
        this.gridApi.showLoadingOverlay();
      } else {
        this.gridApi.hideOverlay();
      }
    }
  },
  beforeMount: function beforeMount() {
    this.gridOptions = {
      defaultColDef: {
        cellStyle: {
          display: 'flex',
          'justify-content': 'start',
          'align-items': 'center'
        },
        resizable: true,
        sortable: true
      },
      rowSelection: {
        mode: 'singleRow'
      },
      suppressCellFocus: true
    };
  },
  methods: {
    getRowId: function getRowId(params) {
      return params.data.cid;
    },
    onGridReady: function onGridReady(params) {
      this.gridApi = params.api;
    },
    onCellClicked: function onCellClicked(event) {
      console.log('cell clicked', event);
    },
    onSelectionChange: function onSelectionChange() {
      this.$emit('selection-change', first(this.gridApi.getSelectedRows()));
    },
    onRowCountChanged: function onRowCountChanged() {
      var _a;
      this.$emit('row-count-changed', (_a = this.filteredLicenceData) === null || _a === void 0 ? void 0 : _a.length);
    },
    exportClients: function exportClients() {
      var _a, _b, _c, _d;
      // excel export
      var wb = createWorkBook();
      var ws = createEmptyWorkSheet(wb, 'Metrics-Lizenz-Check');
      // write header
      var monthidx = 4;
      var col = 0;
      createCell(ws, col++, 0, 'CID', Units.NONE, boldCellStyle);
      createCell(ws, col++, 0, 'Praxis', Units.NONE, boldCellStyle);
      createCell(ws, col++, 0, 'Stripe-Kunde', Units.NONE, boldCellStyle);
      createCell(ws, col++, 0, 'Abo-Start', Units.NONE, boldCellStyle);
      for (var i = 0; i < 12; i++) {
        createCell(ws, col++, 0, "rose: ".concat(this.columnDefs[monthidx + i].headerName), Units.NONE, boldCellStyle);
        createCell(ws, col++, 0, "stripe: ".concat(this.columnDefs[monthidx + i].headerName), Units.NONE, boldCellStyle);
        createCell(ws, col++, 0, "diff: ".concat(this.columnDefs[monthidx + i].headerName), Units.NONE, boldCellStyle);
      }
      // write data
      var mc = createMonths();
      var row = 1; // next after header
      console.log('filteredLicenceData', this.filteredLicenceData);
      if (this.filteredLicenceData) {
        var _iterator2 = _createForOfIteratorHelper(this.filteredLicenceData),
          _step2;
        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var ld = _step2.value;
            col = 0;
            createCell(ws, col++, row, ld.cid, Units.NONE, boldCellStyle);
            createCell(ws, col++, row, ld.praxisname, Units.NONE, boldCellStyle);
            createCell(ws, col++, row, ld.stripeCustomerId, Units.NONE, boldCellStyle);
            createCell(ws, col++, row, ld.started, Units.NONE, boldCellStyle);
            var _iterator3 = _createForOfIteratorHelper(mc),
              _step3;
            try {
              for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
                var m = _step3.value;
                createCell(ws, col++, row, (_a = ld[m]) === null || _a === void 0 ? void 0 : _a.roseusage, Units.NONE, boldCellStyle);
                createCell(ws, col++, row, (_b = ld[m]) === null || _b === void 0 ? void 0 : _b.stripeusage, Units.NONE, boldCellStyle);
                createCell(ws, col++, row, Math.abs(((_c = ld[m]) === null || _c === void 0 ? void 0 : _c.roseusage) - ((_d = ld[m]) === null || _d === void 0 ? void 0 : _d.stripeusage)), Units.NONE, boldCellStyle);
              }
            } catch (err) {
              _iterator3.e(err);
            } finally {
              _iterator3.f();
            }
            row++;
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }
        var widths = [250, 250, 130, 130].concat(_toConsumableArray(Array(36).fill(80)));
        finalizeSimpleXLSXWithMetadata(wb, ws, undefined, col, row, map(widths, function (w) {
          return {
            wpx: w
          };
        }));
        saveXLSX(wb, 'metrics-lizenz-check.xlsx');
      }
    }
  }
});