import numbro from 'numbro';
import { chain, round, split, takeRight } from 'lodash';
import { roseDayjs } from '../../../base';

export const formatDateTime = (row: any, column: any) => {
  const val = walkPropertyPath(row, split(column.property, '.'));
  if (!val) {
    return '-';
  }

  // strict format check
  const parsed = roseDayjs(val, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true);
  if (parsed.isValid()) {
    return parsed.format('DD.MM.YY HH:mm');
  } else {
    return val;
  }
};

export const formatDate = (row: any, column: any) => {
  const val = walkPropertyPath(row, split(column.property, '.'));

  if (!val) {
    return '-';
  }

  // strict format check
  const parsed = roseDayjs(val, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true);
  if (parsed.isValid()) {
    return parsed.format('DD.MM.YY');
  } else {
    return val;
  }
};

export const formatSeconds = (row: any, column: any) => {
  const val = walkPropertyPath(row, split(column.property, '.'));

  if (!val) {
    return '-';
  }
  return `${val} s`;
};

export const formatDuration = (row: any, column: any) => {
  const val = walkPropertyPath(row, split(column.property, '.'));

  if (!val) {
    return '-';
  }
  return numbro(val).format({
    output: 'time',
  });
};

export const formatDurationSimple = (val: any) => {
  if (!val) {
    return '-';
  }
  return numbro(val).format({
    output: 'time',
  });
};

export const formatBinarySize = (row: any, column: any) => {
  const val = walkPropertyPath(row, split(column.property, '.'));

  if (!val) {
    return '-';
  }
  return numbro(val).format({
    output: 'byte',
    base: 'binary',
    mantissa: 1,
  });
};

export const formatMinutes = (row: any, column: any) => {
  const val = walkPropertyPath(row, split(column.property, '.'));

  if (!val) {
    return '-';
  }
  return `${roseDayjs.duration(round(val / 60), 'minutes').humanize()}`;
};

export const fromNow = (row: any, column: any) => {
  const val = walkPropertyPath(row, split(column.property, '.'));

  if (!val) {
    return '-';
  }

  const parsed = roseDayjs(val);
  if (parsed.isValid()) {
    return parsed.fromNow(true);
  } else {
    return val;
  }
};

export const fromNowSimple = (val: any) => {
  if (!val) {
    return '-';
  }

  const parsed = roseDayjs(val);
  if (parsed.isValid()) {
    return parsed.fromNow(true);
  } else {
    return val;
  }
};

export const fromVersion = (row: any, column: { property: string }) => {
  const val = walkPropertyPath(row, split(column.property, '.'));
  if (!val) {
    return '-';
  }
  if (val.includes('.')) {
    return val;
  }
  return takeRight(val, 8).join('') || '-';
};

export const formatBoolean = (row: any, column: any) => {
  const val = walkPropertyPath(row, split(column.property, '.'));

  if (val === undefined || val === null) {
    return '-';
  }

  return val ? 'ja' : 'nein';
};

export const formatJoinArray = (row: any, column: any) => {
  const val = walkPropertyPath(row, split(column.property, '.'));

  if (val === undefined || val === null) {
    return '-';
  }

  if (!(val instanceof Array)) {
    return '-';
  }

  return val.join(', ');
};

export function formatDecimals(value: number) {
  return round(value);
}

export function formatHostFirstPart(value: { prop: string }) {
  return chain(value.prop).split('.').first() || '-';
}

export function formatNumeral(value: any): any {
  return value
    ? numbro(value.prop || value).format({
        thousandSeparated: true,
        mantissa: 1,
      })
    : '-';
}

export const nextTick = () => new Promise(resolve => setTimeout(resolve, 0));

export const generateSort = (path: string[]) => (a: any, b: any) => {
  const aValue = walkPropertyPath(a, path);
  const bValue = walkPropertyPath(b, path);

  // == on purpose to check null and undefined
  if (aValue == null && bValue == null) {
    return 0;
  }
  if (aValue == null) {
    return -1;
  }
  if (bValue == null) {
    return 1;
  }

  // numeric compare on numbers
  if (!isNaN(aValue) && !isNaN(bValue)) {
    return +aValue - +bValue;
  }

  return ('' + aValue).localeCompare('' + bValue);
};

export const walkPropertyPath = (obj: any, path: string[]) => {
  let value = obj;

  if (path) {
    for (const propName of path) {
      if (!value) {
        break;
      }
      value = value[propName];
    }
  }

  return value;
};

export function deepCloneObject(obj: any) {
  return JSON.parse(JSON.stringify(obj));
}

export function openInNewTab(url: string) {
  const win = window.open(url, '_blank');
  if (win) {
    win.focus();
  } else {
    // Popup was blocked
    prompt('Popup was blocked, please copy url:', url);
  }
}

export function arrayFilterOnlyUnique(value: any, index: number, self: any[]) {
  return self.indexOf(value) === index;
}

export function hoursAlarmCssClass(cell: any, hourThreshold: number, className: string, lessThen?: boolean) {
  if (hourThreshold && cell) {
    const cellMoment = roseDayjs(cell);
    const hoursDiff = roseDayjs.duration((roseDayjs as any).utc().diff(cellMoment)).asHours();
    if (lessThen) {
      if (hoursDiff < hourThreshold && className) {
        return className;
      }
    } else if (hoursDiff > hourThreshold && className) {
      return className;
    }
  }
  return '';
}
