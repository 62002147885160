import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.starts-with.js";
import { defineComponent, watch } from 'vue';
import { globalSearchStore } from '@/state/globalSearch.state';
export default defineComponent({
  name: 'ClientFilter',
  props: {
    filterGroup: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      title: 'Client',
      selectedItem: null,
      reset: false
    };
  },
  computed: {
    items: function items() {
      return this.filterGroup.filters;
    }
  },
  watch: {
    selectedItem: {
      handler: function handler(newVal, oldVal) {
        console.log('selectedItem', this.filterGroup.name, newVal, oldVal, newVal === null || newVal === void 0 ? void 0 : newVal.filterExpression, this.reset);
        if (this.reset) {
          console.log('selectedItem NO EMIT', this.filterGroup.name, newVal === null || newVal === void 0 ? void 0 : newVal.filterExpression, this.reset);
          this.reset = false;
          return;
        }
        console.log('selectedItem EMIT', this.filterGroup.name, newVal === null || newVal === void 0 ? void 0 : newVal.filterExpression, this.reset);
        this.$emit('filter', newVal === null || newVal === void 0 ? void 0 : newVal.filterExpression);
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    watch(
    // hier auf die suchzeile gucken. wenn dort etwas kommt was nicht mit unserem namen anfängt solllte wir uns resetten. aber ohne diesen reset zu propagieren
    function () {
      return globalSearchStore.searchInput;
    }, function (newSearchInput) {
      if (_this.selectedItem && (!newSearchInput || !newSearchInput.startsWith(_this.filterGroup.name))) {
        console.log('resetting selected item', _this.filterGroup.name, newSearchInput);
        _this.reset = true;
        _this.selectedItem = null;
      }
    });
  }
});