"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isDemoClient = exports.demoClients = void 0;
const types_1 = require("../../types");
const mainDemoOnboardingDaten = {
    praxisDaten: {
        praxis: {
            name: 'Demo-Praxis',
            nameKurz: 'Demo-Praxis',
            plz: '12345',
            tel: '012345678',
            stadt: 'Musterstadt',
            straße: 'Mustergasse 1',
        },
        auftraggeber: {
            vorname: 'Demo',
            name: 'Dr. Demo',
            email: 'demo@rose.dental',
            plz: '12345',
            stadt: 'Musterstadt',
            straße: 'Mustergasse 1',
        },
        empfehlung: 'demo',
    },
    adminBenutzer: {
        vorname: 'Demo',
        name: 'Dr. Demo',
        email: 'demo@rose.dental',
    },
    zahlungsdaten: {
        kontoinhaber: 'Dr Demo',
        kontoinhaberEmail: 'demo@rose.dental',
        payment_method_id: 'abcd',
    },
    ansprechpartner: {
        vorname: 'Demo',
        name: 'Dr. Demo',
        email: 'demo@rose.dental',
        tel: '012345678',
    },
    rechenzentrenData: {
        test: {
            user: 'rzdemo',
            password: 'rzdemo',
        },
    },
    kommentar: 'alles gut',
};
exports.demoClients = [
    {
        cid: 'demo',
        maindemo: true,
        praxisName: mainDemoOnboardingDaten.praxisDaten?.praxis.name || '-',
        praxisKurzName: mainDemoOnboardingDaten.praxisDaten?.praxis.nameKurz || '-',
        praxisEmail: 'DemoPraxis@rose.dental',
        praxisTelefon: '000/12345678',
        billingonly: false,
        veraltet: false,
        tags: ['demo', 'test'],
        strasse: 'Demostr. 1',
        stadt: 'Demo-Stadt',
        plz: '12345',
        website: 'www.rose.dental',
        debug: false,
        onboardingDaten: mainDemoOnboardingDaten,
        pvs: types_1.PvsEnum.CHARLY,
        package: types_1.PACKAGES.ROSEMETRICS,
    },
    {
        cid: 'testca',
        maindemo: true,
        praxisName: 'ca Test-Praxis',
        praxisKurzName: 'ca Test-Praxis',
        praxisEmail: 'ca TestPraxis@rose.dental',
        praxisTelefon: '000/12345678',
        billingonly: false,
        veraltet: false,
        tags: ['demo', 'test'],
        strasse: 'Demostr. 1',
        stadt: 'Demo-Stadt',
        plz: '12345',
        website: 'www.rose.dental',
        debug: false,
        onboardingDaten: undefined,
        pvs: types_1.PvsEnum.CHARLY,
        package: types_1.PACKAGES.CHARLYANALYTICS,
    },
    {
        cid: 'testevident',
        maindemo: true,
        praxisName: 'EVIDENT Test-Praxis',
        praxisKurzName: 'EVIDENT Test-Praxis',
        praxisEmail: 'EVIDENT TestPraxis@rose.dental',
        praxisTelefon: '000/12345678',
        billingonly: false,
        veraltet: false,
        tags: ['demo', 'test'],
        strasse: 'Demostr. 1',
        stadt: 'Demo-Stadt',
        plz: '12345',
        website: 'www.rose.dental',
        debug: false,
        onboardingDaten: undefined,
        pvs: types_1.PvsEnum.EVIDENT,
        package: types_1.PACKAGES.ROSEMETRICS,
    },
];
const isDemoClient = (cid) => exports.demoClients.some(c => c.cid === cid);
exports.isDemoClient = isDemoClient;
