import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VMenu, {
    attrs: {
      "offset-y": "",
      "max-height": "500px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c(VChip, _vm._g(_vm._b({
          attrs: {
            "small": "",
            "label": "",
            "color": _vm.filterGroup.bg,
            "outlined": !_vm.selectedItem,
            "dark": _vm.filterGroup.dark
          }
        }, 'v-chip', attrs, false), on), [_c('span', {
          staticClass: "font-weight-bold pr-1"
        }, [_vm._v(_vm._s(_vm.filterGroup.name))]), _vm.selectedItem ? _c('span', [_vm._v(_vm._s(_vm.selectedItem.name) + " " + _vm._s(_vm.selectedItem.d.count))]) : _c(VIcon, {
          attrs: {
            "x-small": ""
          }
        }, [_vm._v("fas fa-filters")])], 1)];
      }
    }])
  }, [_c(VList, {
    attrs: {
      "dense": ""
    }
  }, [_c(VListItemGroup, {
    attrs: {
      "color": "primary"
    },
    model: {
      value: _vm.selectedItem,
      callback: function callback($$v) {
        _vm.selectedItem = $$v;
      },
      expression: "selectedItem"
    }
  }, _vm._l(_vm.items, function (item, index) {
    return _c(VListItem, {
      key: index,
      "class": {
        "odd-item": index % 2 === 0
      },
      attrs: {
        "value": item
      }
    }, [_c(VListItemContent, [_c(VListItemTitle, {
      staticClass: "list-item-title"
    }, [_vm._v(_vm._s(item.name))]), _c(VListItemSubtitle, {
      staticClass: "list-item-body"
    }, [_c('div', {
      staticClass: "d d-flex caption align-center justify-space-between"
    }, [_c('span', [_vm._v(_vm._s(item.d.count))]), _c('span', [_vm._v(_vm._s(item.d.percentage) + "%")])]), item.v2 ? _c('div', {
      staticClass: "v2 d-flex justify-end"
    }, [_vm._v(_vm._s(item.v2))]) : _vm._e()])], 1)], 1);
  }), 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };