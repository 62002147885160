"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createHVMApi = void 0;
function createHVMApi(api) {
    return {
        getHVMNordrheinVerlauf: (queryParams) => api.metrics.get(`/api/hvm/nordrhein/verlauf`, {
            query: queryParams,
        }),
        getHVMNordrhein: (queryParams) => api.metrics.get(`/api/hvm/nordrhein`, {
            query: queryParams,
        }),
        getHVMNordrheinDetails: (von, bis, altervon, alterbis, ignorekzv, refType, part, partdetail, behandlerId) => api.metrics.post(`/api/hvm/nordrhein/details`, {
            von,
            bis,
            altervon,
            alterbis,
            ignorekzv,
            part,
            refType,
            partdetail,
            behandlerId,
        }),
        getHVMNiedersachsenVerlauf: (queryParams) => api.metrics.get(`/api/hvm/nordrhein/verlauf`, {
            query: queryParams,
        }),
        getHVMNiedersachsen: (queryParams) => api.metrics.get(`/api/hvm/niedersachsen`, {
            query: queryParams,
        }),
        getHVMNiedersachsenDetails: (von, bis, altervon, alterbis, ignorekzv, refType, part, partdetail, behandlerId) => api.metrics.post(`/api/hvm/niedersachsen/details`, {
            von,
            bis,
            altervon,
            alterbis,
            ignorekzv,
            part,
            refType,
            partdetail,
            behandlerId,
        }),
        getHVMBayern: (queryParams) => api.metrics.get(`/api/hvm/bayern`, {
            query: queryParams,
        }),
        getHVMBayernPatientDetails: (von, bis, altervon, alterbis, ignorekzv, part, fallgruppe, partdetail, behandlerId) => api.metrics.post(`/api/hvm/bayern/details`, {
            von,
            bis,
            altervon,
            alterbis,
            ignorekzv,
            part,
            fallgruppe,
            partdetail,
            behandlerId,
        }),
        getHVMWestfalenLippe: (params) => api.metrics.post(`/api/hvm/westfalen-lippe`, params),
        getHVMWestfalenLippeDetails: (von, bis, altervon, alterbis, ignorekzv, teil, row, fallgruppe, behandlerId) => api.metrics.post(`/api/hvm/westfalen-lippe/details`, {
            von,
            bis,
            altervon,
            alterbis,
            ignorekzv,
            teil,
            row,
            fallgruppe,
            behandlerId,
        }),
    };
}
exports.createHVMApi = createHVMApi;
