import { IRoseAGGridColumn, dateFormatter } from '@rose/common-ui';
import { roseDayjs } from '../../../../base';

export function createMonths(): string[] {
  const ret = [];
  for (let i = 0; i < 12; i++) {
    const m = roseDayjs().subtract(i, 'months').format('YYYY-MM-01');
    ret.push(m);
  }
  return ret;
}

function createMonthColumns(): IRoseAGGridColumn<any>[] {
  const cols: IRoseAGGridColumn<any>[] = [];
  for (const m of createMonths()) {
    cols.push({
      headerName: roseDayjs(m).format('MM.YYYY'),
      valueGetter: params => {
        if (params?.data?.isSummaryRow) {
          return params?.data?.[m];
        }
        // subscriptions started after the month are not counted
        if (!params?.data?.[m]?.active) {
          return '-';
        }
        return `${params?.data?.[m].roseusage ?? 0} / ${params?.data?.[m].stripeusage ?? 0}`;
      },
      // eslint-disable-next-line complexity
      cellClass: params => {
        if (params?.data?.isSummaryRow) {
          return ['checkcell'];
        }
        if (!params?.data?.[m]?.active) {
          return ['not-started', 'checkcell'];
        }
        if (params?.data?.[m].roseusage === 0 || params?.data?.[m].stripeusage === 0) {
          return ['null-problem', 'checkcell'];
        }
        const diff = Math.abs((params?.data?.[m].roseusage ?? 0) - (params?.data?.[m].stripeusage ?? 0));
        if (diff >= 2) {
          return ['problem', 'checkcell'];
        }
        if (diff >= 1) {
          return ['semi-problem', 'checkcell'];
        }
        return ['checkcell'];
      },
      headerTooltip: 'Monat: ' + m,
      width: 100,
      comparator: (x, y, a, b) => {
        const aVal = Math.abs((a?.data?.[m].roseusage ?? 0) - (a?.data?.[m].stripeusage ?? 0)) ?? 0;
        const bVal = Math.abs((b?.data?.[m].roseusage ?? 0) - (b?.data?.[m].stripeusage ?? 0)) ?? 0;
        return aVal - bVal;
      },
    });
  }
  return cols;
}

export function metricsLicenceCheckColumns(smallScreen: boolean): IRoseAGGridColumn<any>[] {
  return [
    {
      headerName: 'Customer-ID',
      field: 'cid',
      sort: 'asc',
      cellRenderer: 'ClientsClientIdCell',
      headerTooltip: 'Eindeutige ID des Kunden',
      width: 250,
      pinned: smallScreen ? undefined : 'left',
    },
    {
      headerName: 'Praxis',
      field: 'praxisname',
      cellRenderer: 'ClientsClientIdCell',
      headerTooltip: 'Name des Kunden',
      width: 250,
    },
    {
      headerName: 's',
      field: 'stripeCustomerId',
      cellRenderer: 'GoToStripeCell',
      headerTooltip: 'Zum stripe Kunden springen',
      width: 50,
    },
    {
      headerName: 'Start',
      field: 'started',
      headerTooltip: 'Start des Abo',
      valueFormatter: dateFormatter,
      width: 100,
    },
    // {
    //   headerName: 'Mini-Chart',
    //   field: 'chart',
    //   cellRenderer: 'agSparklineCellRenderer',
    //   headerTooltip: 'Eindeutige ID des Abonnements',
    //   width: 240,
    // },
    ...createMonthColumns(),
  ];
}
