import moment from 'moment';
import Vue from 'vue';
import App from './App.vue';
import router from './router';

import vuetify from '@/plugins/vuetify';
import VueClipboard from 'vue-clipboard2';
import VTooltip from 'v-tooltip';

import 'jsoneditor/dist/jsoneditor.min.css';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en';
import { initGlobalComponents } from './globalComponents';
import VueKonva from 'vue-konva';
import vuemoment from 'vue-moment';
import { modifyFaviconIfStaging, decimalFilter, euroFilter, dauerFilter, tzFilter } from '../../common-ui';
import { registerRoseGlobalComponents } from '@rose/common-ui/src/components/registerRoseGlobalComponents';
import { registerRoseGlobalDirectives } from '@rose/common-ui/src/directives/registerRoseGlobalDirectives';
import { highchartsOptions } from '@rose/common-ui/src/helpers/highchartOptions';
import VueVirtualScroller from 'vue-virtual-scroller';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

import '@rose/common-ui/src/fonts/roboto';
import '@rose/common-ui/src/fonts/material-icons';
import '@rose/common-ui/src/fonts/font-awesome';
// TODO: remove mdi icons, replace all mdi- icons with fa- icons
import '@rose/common-ui/src/fonts/mdi';

import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import VueSplit from 'vue-split-panel';
import HighchartsVue from 'highcharts-vue';
import Highcharts from 'highcharts';
import More from 'highcharts/highcharts-more';

Vue.use(VueSplit);
// set moment locale
moment.locale('de');
Vue.use(vuemoment);

Vue.use(VueVirtualScroller);

modifyFaviconIfStaging();

import { Icon } from 'leaflet';
import { isString } from 'lodash';

// hack icon fix
const iconproto: any = Icon.Default.prototype;
delete iconproto._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

Vue.config.productionTip = false;
Vue.config.devtools = true;

Vue.use(VueClipboard);
Vue.use(ElementUI, { locale });
Vue.use(HighchartsVue);
Highcharts.setOptions(highchartsOptions);
More(Highcharts);
Vue.use(VueKonva);
Vue.use(VTooltip, {
  popover: {
    defaultContainer: '[data-app]',
    defaultBoundariesElement: document.body,
  },
});

Vue.filter('pretty', (value: any) => {
  if (isString(value)) {
    return JSON.stringify(JSON.parse(value), null, 2);
  }
  return JSON.stringify(value, null, 2);
});
Vue.filter('euro', euroFilter.filters.euro);
Vue.filter('decimal', decimalFilter.filters.decimal);
Vue.filter('dauer', dauerFilter.filters.dauer);
Vue.filter('dauerShort', dauerFilter.filters.dauerShort);
Vue.filter('tz', tzFilter.filters.tz);

Vue.mixin({
  data: () => ({
    get filters() {
      return {
        euro: euroFilter.filters.euro,
        decimal: decimalFilter.filters.decimal,
        dauer: dauerFilter.filters.dauer,
        dauerShort: dauerFilter.filters.dauerShort,
        tz: tzFilter.filters.tz,
      };
    },
  }),
});

// extend Vue type to include $filters
declare module 'vue/types/vue' {
  interface Vue {
    euro: any;
    decimal: any;
    dauer: any;
    dauerShort: any;
    tz: any;

    filters: {
      euro: typeof euroFilter.filters.euro;
      decimal: typeof decimalFilter.filters.decimal;
      dauer: typeof dauerFilter.filters.dauer;
      dauerShort: typeof dauerFilter.filters.dauerShort;
      tz: typeof tzFilter.filters.tz;
    };
  }
}

initGlobalComponents();
registerRoseGlobalComponents(Vue);
registerRoseGlobalDirectives({ Vue, router });

new Vue({
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app');
