"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createR4cR4cApi = void 0;
function createR4cR4cApi(api) {
    return {
        getInitData: (params) => api.r4cDispatch.get(`/factoring/initdata/${params.credentialsKey}`, {
            query: { user: params.user },
        }),
        getProfile: () => api.r4cDispatch.get(`/factoring/factoringprofile`),
        getPraxisDaten: () => api.r4cDispatch.get(`/factoring/praxisdaten`),
        setCharlyTemplates: (params) => api.r4cInstance.post(`/factoring/charlyTemplates`, params),
        getCharlyTemplates: () => api.r4cInstance.get(`/factoring/charlyTemplates`),
        setPraxisDaten: (params) => api.r4cInstance.post(`/factoring/praxisdaten`, params),
        // no auth routes:
        isApiKeyRegistered: (params) => api.r4cDispatch.get(`/r4cinit/isapikeyregistered/${params.apikey}`, {
            query: { praxis: params.praxis },
        }),
        createApiKey: (params) => api.r4cDispatch.get(`/r4cinit/createapikey/${params.noise}`, {
            query: { notimestamp: params.notimestamp, licence: params.licence },
        }),
        registerApiKey: (apikey) => api.r4cDispatch.get(`/r4cinit/registerapikey/${apikey}`),
        checkRegisterToken: (token) => api.r4cDispatch.get(`/r4cinit/checkregistertoken/${token}`),
        checkApiKey: (apikey) => api.r4cDispatch.get(`/r4cinit/checkapikey/${apikey}`),
        checkCertPassword: (params) => api.r4cDispatch.post(`/r4cinit/checkcertificate`, params),
        downloadBFSCertificate: (params) => api.r4cDispatch.post(`/r4cinit/downloadbfscertificate`, params),
    };
}
exports.createR4cR4cApi = createR4cR4cApi;
