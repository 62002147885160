import { defineComponent } from 'vue';
import { roseDayjs } from '../../../../base';
export default defineComponent({
  name: 'AboStatusCell',
  data: function data() {
    return {
      params: {}
    };
  },
  computed: {
    aboStatus: function aboStatus() {
      return this.params.value;
    },
    aboStatusColor: function aboStatusColor() {
      switch (this.aboStatus) {
        case 'good':
          return 'success';
        case 'bad':
          return 'error';
        default:
          return 'warning';
      }
    },
    reason: function reason() {
      var _a, _b, _c, _d;
      if (!this.params.value && ((_a = this.params.data) === null || _a === void 0 ? void 0 : _a.rosefrom) && !((_b = this.params.data) === null || _b === void 0 ? void 0 : _b.stripefrom)) {
        return 'No Stripe';
      }
      if (!this.params.value && roseDayjs((_c = this.params.data) === null || _c === void 0 ? void 0 : _c.roseto).isAfter(roseDayjs((_d = this.params.data) === null || _d === void 0 ? void 0 : _d.stripeto))) {
        return 'Stripe Expired';
      }
      return '';
    }
  }
});