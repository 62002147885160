import { defineComponent } from 'vue';
import { RoseTicketStatus } from '../../../../types';
export default defineComponent({
  name: 'TicketStatusCell',
  data: function data() {
    return {
      params: {},
      ticketStatus: RoseTicketStatus
    };
  },
  computed: {
    text: function text() {
      switch (this.params.value) {
        case RoseTicketStatus.Open:
          return 'Offen';
        case RoseTicketStatus.Pending:
          return 'In Bearbeitung';
        case RoseTicketStatus.Resolved:
          return 'Gelöst';
        case RoseTicketStatus.Closed:
          return 'Geschlossen';
        case RoseTicketStatus.WaitingOnCustomer:
          return 'Warte auf Kunden';
        case RoseTicketStatus.WaitingOnThirdParty:
          return 'Warte auf Drittanbieter';
        case RoseTicketStatus.CallBack:
          return 'Rückmelden';
        case RoseTicketStatus.InternalClarification:
          return 'Interne Klärung';
        default:
          return '';
      }
    },
    color: function color() {
      switch (this.params.value) {
        case RoseTicketStatus.Open:
          return 'error';
        case RoseTicketStatus.Pending:
        case RoseTicketStatus.CallBack:
        case RoseTicketStatus.InternalClarification:
          return 'warning';
        case RoseTicketStatus.Resolved:
          return 'success';
        case RoseTicketStatus.Closed:
        case RoseTicketStatus.WaitingOnCustomer:
        case RoseTicketStatus.WaitingOnThirdParty:
          return 'grey';
        default:
          return '';
      }
    }
  }
});